<template>
    <div oncontextmenu="return false">
        <div v-if="errorNoSubscribe">
            <NoSubscribeError :errorNoSubscribe="errorNoSubscribe" :closeNoErrorSubscribeModal="closeNoErrorSubscribeModal" />
        </div>
        <Navbar :logoutsp="logoutsp"  :route="route" />
        <section class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 lg:mx-52 mx-3 my-0">
            <ul class="grid grid-cols-1 sm:grid-cols-3 gap-8">
                <li v-for="item in years" :key="item.id" @click="passeYear(item.id)" class="year-block">
                <a class=" hover:bg-red-400 cursor-pointer block px-8 py-4" >
                    <dl class="grid sm:block lg:grid xl:block grid-cols-1 sm:grid-rows-1 justify-center items-center">
                    <div>
                        <dt class="flex flex-row">
                            <div v-for="i in item.id" :key="i" :class="subscriptionsList.includes(item.id.toString()) ? `w-4 h-4 rounded-full ${colors[i-1]} mb-2 m-1` : `w-4 h-4 rounded-full bg-gray-300 mb-2 m-1`" ></div>
                        </dt>
                        <dd class="leading-6 font-medium flex flex-col">
                           <div>{{item.title}}</div> 
                           <div :class="item.module_subcribed ? 'mb-0' : 'mb-2.5'">
                              <span v-if="item.id != 1" :class="`t${colors[item.id-2]} text-xs`"> {{item.modules}} modules</span>
                              <span v-else class="text-blue-400 text-xs"> {{item.modules}} modules</span>
                              <span v-if="item.unités != 0 && item.unités != 1" :class="`t${colors[item.id-1]} text-xs mx-2`">{{item.unités}} unités</span>
                              <span v-if="item.unités == 1" :class="`t${colors[item.id-1]} text-xs mx-2`">{{item.unités}} unité</span>
                           </div>
                          <div class="sm:block hidden">
                          <el-popover :width="300" trigger="hover">
                            <template #reference>
                              <div class="flex flex-row">
                                <div class="flex flex-row" v-if="(item.id == 1)">
                                  <svg v-for="i in item.module_subcribed" :key="i" aria-hidden="true" focusable="false" data-prefix="fas"
                                    data-icon="star" class="w-3 mr-1 text-blue-400" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512">
                                    <path fill="currentColor"
                                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                    </path>
                                  </svg>
                                </div>
                                <svg v-for="j in item.module_subcribed" :key="j" aria-hidden="true" focusable="false" data-prefix="fas"
                                  data-icon="star" :class="`t${colors[item.id - 2]} w-3 mr-1`" role="img" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512">
                                  <path fill="currentColor"
                                    d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                  </path>
                                </svg>
                              </div>
                            </template>
                            <el-table  :data="item.modules_titles" >
                              <el-table-column width="250" prop="title" label="Modules" />
                            </el-table>
                          </el-popover>
                          </div>
                          <div class="sm:hidden block">
                            <el-popover :width="300" trigger="click">
                              <template #reference>
                                <div class="flex flex-row">
                                  <div class="flex flex-row p-2" v-if="(item.id == 1)">
                                    <svg v-for="i in item.module_subcribed" :key="i" aria-hidden="true" focusable="false" data-prefix="fas"
                                      data-icon="star" class="w-3 mr-1 text-blue-400" role="img" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512">
                                      <path fill="currentColor"
                                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                      </path>
                                    </svg>
                                  </div>
                                  <svg v-for="j in item.module_subcribed" :key="j" aria-hidden="true" focusable="false" data-prefix="fas"
                                    data-icon="star" :class="`t${colors[item.id - 2]} w-3 mr-1`" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512">
                                    <path fill="currentColor"
                                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                    </path>
                                  </svg>
                                </div>
                              </template>
                              <el-table :data="item.modules_titles">
                                <el-table-column width="250" prop="title" label="Modules" />
                              </el-table>
                            </el-popover>
                          </div>
                        </dd>
                    </div>
                    <div>
                        <dd class="flex flex-row justify-center group-hover:text-light-blue-200 text-center p-5 font-medium sm:mb-4 lg:mb-0 xl:mb-4">
                            <p class="text-5xl mx-2"> {{ item.id }} </p>
                            <p class="ordinal">année</p>
                        </dd>
                    </div>
                    </dl>
                </a>
                </li>
            </ul>
            <div class="flex justify-center items-center py-6 ">
                <div class="flex flex-row items-center sm:w-2/3 w-full rounded-lg">
                    <el-input @input="change" class="w-3/4 mr-3" v-model="code" placeholder="XXXX-XXXX-XXXX-XXXX" clearable maxlength="19" show-word-limit />
                    <button @click="validate" class="w-1/4 py-3 px-4 primary-btn">Activer</button>
                </div> 
            </div>
        </section>
    </div>
</template>

<script>

document.onkeydown = function(e) {
  if(event.keyCode == 123) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode == 'C'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode == 'X'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
     return false;
  }
}
import NoSubscribeError from '../dashboard/SubscriptionModals/NoSubscribeError.vue'
import Navbar from "../tools/Navbar.vue";
import axios from "axios";
import {StarFilled} from '@element-plus/icons';
import { ElMessage } from 'element-plus';
import { h } from 'vue'
export default {
   components: {
    Navbar,
    StarFilled,
    NoSubscribeError
  },
  data () {
     return {
        isLoading: false,
        errorNoSubscribe: false,
        route:null,
        yearstudy: null,
        search: '',
        selectedYear: '',
        subscriptionsList : [],
        code:null,
        years:[
            {
                id: 1,
                title: 'Première Année',
                modules : 10,
                unités : 0,
                module_subcribed : 0,
            modules_titles : []
            },
            {
                id: 2,
                title: 'Deuxième Année',
                modules : 2,
                unités: 5,
                module_subcribed: 0,
              modules_titles: []
            },
            {
                id: 3,
                title: 'Troisième Année',
                modules : 5,
                unités: 4,
                module_subcribed: 0,
                modules_titles: []
            },
            {
                id: 4,
                title: 'Quatrième Année',
                modules : 4,
                unités: 1,
                module_subcribed: 0,
              modules_titles: []
            },
            {
                id: 5,
                title: 'Cinquième Année',
                modules : 6,
                unités: 0,
                module_subcribed: 0,
              modules_titles: []
            },
            {
                id: 6,
                title: 'Sixième Année',
                modules : 10,
                unités: 0,
                module_subcribed: 0,
              modules_titles: []
            },
        ],
        colors : ["blue","red","green","yellow","purple","pink"]
     }
   },
    mounted () {
        this.route = this.$route.name;
        this.yearstudy = this.User.year;
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_BASE_URL+"/getusersubscribes", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({data}) => {
          this.changeSubscribeList(data.result)
          this.subscriptionsList = data.result
          if (this.subscriptionsList.length == 0) {
            this.errorNoSubscribe = true
          }
        })
        .catch(() => {
        });
      axios.get(process.env.VUE_APP_API_BASE_URL+"/getusersubscribesmodulesofyear", {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
        .then(({ data }) => {
          this.years.forEach(year => {
            data.result.forEach(res => {
              if (res.year == year.id) {
                year.module_subcribed = parseInt(res.subscribed)
                if (res.subscribed > 1){
                  var tmp = []
                  res.modules_year.split(',').forEach(splited => {
                    tmp.push({title: splited})
                  })
                  year.modules_titles = tmp

                }else{
                  year.modules_titles = [{title : res.modules_year}]
                }
                
              }
            })
            if (this.subscriptionsList.length == 0 & this.errorNoSubscribe & year.modules_titles.length != 0) {
                this.errorNoSubscribe = false
            }
          })
          
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false;
        });

    },

    methods : {

      refresh_modules(){
        axios.get(process.env.VUE_APP_API_BASE_URL+"/getusersubscribesmodulesofyear", {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
        })
        .then(({ data }) => {
          this.years.forEach(year => {
            data.result.forEach(res => {
              if (res.year == year.id) {
                year.module_subcribed = parseInt(res.subscribed)
                if (res.subscribed > 1) {
                  var tmp = []
                  res.modules_year.split(',').forEach(splited => {
                    tmp.push({ title: splited })
                  })
                  year.modules_titles = tmp

                } else {
                  year.modules_titles = [{ title: res.modules_year }]
                }

              }
            })
          })

          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false;
        });
      },
      change(){
        this.code = this.spacify(this.code,4,"-");
        this.code = this.code.toLowerCase();
      },
      spacify(str, after, c) {
          after = after || 4;
          c = c || " ";
          var v = str.replace(/[^\dA-Za-z]/g, ''),
              reg = new RegExp(".{" + after + "}", "g");
          return v.replace(reg, function (a) {
              return a + c;
          }).replace(/[^0-9A-Za-z]+$/, "");
      },

        closeNoErrorSubscribeModal(){
            this.errorNoSubscribe = false
        },

        passeYear(year){
            // this.selectedYear = year
            this.changeSubscribeNow(year);
            this.$router.push("/dashboard");
        },

        changeSubscribeNow(action) {
            this.$store.commit("set_subscribeNow", action);
        },

        changeSubscribeList(action) {
            this.$store.commit("set_subscriptions", action);
        },

        logoutsp() {
            this.$store.dispatch("logoutAction").then(() => {
            this.$router.push("/login");
            }).catch(() => {});
        },
        validate(){
            axios.put(process.env.VUE_APP_API_BASE_URL+"/verificationcode",
                {
                    Activation_code: this.code,
                },
                {
                    headers: {
                        Authorization: "Bearer" + this.isToken,
                    },
                }
              ).then(({data}) => {
                this.code = ''
                switch(data.result) {
                    case 'Successful':
                    if (data.mode === 'annuel'){
                          if(data.year != '0'){
                               this.subscriptionsList.push(data.year)
                               if(data.year == '1'){
                                   this.success("1ere année ");
                               }else{
                                   var s = data.year + "eme année "
                                   this.success(s);
                               }
                           }else{
                               this.subscriptionsList = ['1','2','3','4','5','6']
                               this.success("Résidanat ");
                           }
                           this.changeSubscribeList(this.subscriptionsList)
                          }
                    else if (data.mode === 'modulaire') {
                      this.success(' '+data.module.title+' ');
                      this.refresh_modules();
                      }
                          break;
                    case "Code deja actif":
                       this.errorm_code_actif()
                       break;
                    case "Code expiree":
                       this.errorm_code_expire()
                       break;
                    case "Code invalide":
                       this.errorm_code_invalide()
                       break;
                }
              }).catch(() => {

              });
        }
    },

    computed:{
        User() {
            return this.$store.getters.get_user;
        },

        isToken() {
            return this.$store.getters.get_token;
        },

        startLoading() {
            this.isLoading = true;
        },

        endLoading() {
          this.isLoading = true;
        },
    },
    setup() {

      const success = (year) => {
        ElMessage({
          message: h('p', { style: 'color: #65a30d' }, [
           
            h('span', null , 'Votre abonnement de '),
            h('span', { style: 'font-weight: bold' }, year),
            h('span', null , 'a été activer avec succès'),
          ]),
          type: 'success',
        })
      }

      const errorm_code_actif = () => {
        ElMessage({
          message: h('p', { style: '' }, [
           
            h('span', null , 'Ce code est '),
            h('span', { style: 'font-weight: bold' }, 'deja activé '),
            h('span', null , 'veuillez réessayer avec un autre code !'),
          ]),
          type: 'error',
        })
      }

      const errorm_code_expire = () => {
        ElMessage({
          message: h('p', { style: '' }, [
           
            h('span', null , 'Ce code est '),
            h('span', { style: 'font-weight: bold' }, 'expiré '),
            h('span', null , 'veuillez réessayer avec un autre code !'),
          ]),
          type: 'error',
        })
      }

      const errorm_code_invalide = () => {
        ElMessage({
          message: h('p', { style: '' }, [
           
            h('span', null , 'Ce code est '),
            h('span', { style: 'font-weight: bold' }, 'invalide '),
            h('span', null , 'veuillez réessayer avec un autre code !'),
          ]),
          type: 'error',
        })
      }
      
      return{success,errorm_code_actif,errorm_code_expire,errorm_code_invalide}
      
    },
}
</script>

<style>
.shadow-code{
    box-shadow:
 /* 5px 10px 20px #e5e7eb,
 5px -10px 20px  #e5e7eb,
-5px 10px 20px #e5e7eb,
-5px -10px 20px #e5e7eb; */
0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
</style>